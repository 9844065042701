@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@400;700&display=swap");

/* ____________________________________________ */

* {
    font-family: "Blinker", sans-serif;
}

.group {
    color: rgba(255, 255, 255, 0.8);
    height: 100%;
}

.carousel .slide img {
    height: 100%;
}

.image-heading-style {
    height: 100%;
    width: 100%;
}

.carousel .control-dots {
    bottom: -40px;
}

.carousel.carousel-slider {
    overflow: unset;
}

.carousel .slider-wrapper {
    width: 99% !important;
    border-radius: 10px;
}

.background-gradient {
    background: linear-gradient(0deg, #000d25 0%, rgba(0, 0, 0, 0) 60%);
}

.control-dots {
    display: none;
}

#map-svg {
    overflow: visible;
}

.nav-tabs .nav-link {
    font-size: 12px;
    background: transparent !important;
    color: white !important;
}

.wrapper {
    width: 100%;
}

.mobile-gradient {
    background-image: linear-gradient(180deg, #000d25 0%, rgba(0, 0, 0, 0) 60%);
}
.header-gradient {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-image: linear-gradient(0deg, #000d25ff 20%, #000d2500 100%);
}

/* swiper buttons left and right */
.swiper-button-next {
    top: 30% !important;
    right: -6px !important;
    font-weight: bold;
}
.swiper-button-prev {
    top: 30% !important;
    left: -6px !important;
    font-weight: bold;
}
.swiper-button-next:after {
    font-size: 25px !important;
}
.swiper-button-prev:after {
    font-size: 25px !important;
}

.swiper-slide {
    height: initial !important;
}

/* map links */
.map-label g text {
    fill: white !important;
    text-shadow: 2px 2px 3px #fff;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.map-label > g:hover text {
    text-shadow: none;
    translate: 3px -1px;
}
/* map icons */
.map-label g circle {
    filter: drop-shadow(2px 2px 5px rgba(255, 255, 255, 0.5));
}
.map-label > g:hover circle {
    filter: none;
}

@media (min-width: 841px) {
    .main-scroll {
        width: 38%;
        float: right;
    }
}
@media (min-width: 841px) {
    .place-section {
        background: rgb(255, 255, 255);
        /* background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,13,37,0.95) 15%, rgba(0,13,37,0.50) 100%); */
        background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(0, 13, 37, 0.7) 15%,
            rgba(0, 13, 37, 0.7) 85%,
            rgba(255, 255, 255, 0) 100%
        );
        backdrop-filter: blur(7px);
    }
}

@media (min-width: 841px) {
    .carousel.carousel-slider {
        overflow: unset !important;
    }
}

/* media query for swiper buttons */
@media (max-width: 841px) {
    .swiper-button-next {
        top: 30% !important;
        right: -9px !important;
    }
    .swiper-button-prev {
        top: 30% !important;
        left: -9px !important;
    }
    .swiper-button-next:after {
        font-size: 16px !important;
    }
    .swiper-button-prev:after {
        font-size: 16px !important;
    }
}
